import React from "react";
import {css, Theme} from "@emotion/react";

import {flexJustifyCenter, position, w100, zIndex} from "@pg-design/helpers-css";
import {Picture} from "@pg-design/picture-module";

import {useBoundingClientRect} from "../../hooks/use_bounding_client_rect";
import {HomepageHeaderContent} from "./HomepageHeaderContent";
import {HomepageSearch} from "./HomepageSearch";

const headerXl = require("../images/header_xl.png");
const headerLg = require("../images/header_lg.png");
const headerMd = require("../images/header_md.png");
const headerSm = require("../images/header_sm.png");
const headerXs = require("../images/header_xs.png");

export const HomepageHeader = () => {
    const {boundingClientRect: headerBoundingClientRect, elementRef: headerElementRef} = useBoundingClientRect<HTMLDivElement>();
    const {boundingClientRect: contentBoundingClientRect, elementRef: contentElementRef} = useBoundingClientRect<HTMLDivElement>();

    // We need to  calculate search blend height - this will be viewport height minus header (dynamic) and navigation height (60px)
    const blendHeight = `${(headerBoundingClientRect?.height || 0) + 60}px`;
    // We need to calculate search blend top position - this will be bottom position of header content minus search input height with its margin (~60px)
    // Bear in mind this value is not update on window resize event - this is edge case
    const blendTopPosition = contentBoundingClientRect ? `${Math.floor(contentBoundingClientRect.bottom - 60)}px` : 0;

    return (
        <section css={homepageHeader} ref={headerElementRef}>
            <Picture
                sources={[
                    {minWidthPX: 0, src: headerXs, height: 360, width: 448},
                    {minWidthPX: 360, src: headerSm, height: 600, width: 448},
                    {minWidthPX: 600, src: headerMd, height: 588, width: 1366},
                    {minWidthPX: 1366, src: headerLg, height: 826, width: 1919},
                    {minWidthPX: 1919, src: headerXl, height: 1102, width: 2560}
                ]}
                alt="header"
                css={headerImageCss}
                fetchPriority="high"
            />

            <div ref={contentElementRef} css={homepageHeaderContentPositioner}>
                <HomepageHeaderContent />
                <HomepageSearch containerHeight={blendHeight} blendTopPosition={blendTopPosition} />
            </div>
        </section>
    );
};

// INFO: `height` and `breakpoints` has connection with picture size
const homepageHeader = (theme: Theme) => css`
    ${position("relative")};
    ${flexJustifyCenter};
    overflow: hidden;

    @media (min-width: 360px) {
        min-height: 290px;
    }

    @media (min-width: 600px) {
        min-height: 220px;
    }

    @media (min-width: ${theme.breakpoints.md}) {
        overflow: visible;
        min-height: 240px;
        overflow: visible;
    }

    @media (min-width: 1366px) {
        min-height: 500px;
    }

    @media (min-width: 1919px) {
        min-height: 730px;
    }
`;

const homepageHeaderContentPositioner = (theme: Theme) => css`
    position: absolute;
    left: 0;
    bottom: 3.6rem;
    ${w100};
    ${zIndex(10)};

    @media (min-width: ${theme.breakpoints.md}) {
        top: 50%;
        bottom: unset;
        transform: translateY(-50%);
    }
`;

const headerImageCss = (theme: Theme) => css`
    img {
        max-width: initial;

        @media (min-width: ${theme.breakpoints.sm}) {
            max-width: 100%;
        }
    }
`;
