import {combineReducers, Reducer} from "redux";

import {reduceResponse} from "@pg-mono/request-state";

import {BRAND_NAME} from "../../app/constants/company";
import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";
import {customMetaActionTypes, SeoMetaDataType} from "../actions/fetch_custom_meta_data";
import {IUpdateMetaDataAction, IUpdateMetaTitleDescriptionAction} from "../actions/update_meta_data";

export interface IMetaDataStore {
    standardMetaData: IStandardMetaData;
    customMetaData?: SeoMetaDataType;
}

export interface IStandardMetaData {
    title: string;
    description: string;
    canonical: string;
    prev?: string;
    next?: string;
    robots?: string;
    dimensionRegion?: string;
    gtmPage?: string;
    ogImage?: string;
    ogDescription?: string;
    ogTitle?: string;
    ogType?: string;
    ogUrl?: string;
    ogPublishDate?: string;
    hreflang?: {language_code: string; slug: string}[];
    lang: string;
}

export const rpCanonicalUrl = "https://rynekpierwotny.pl";

export const defaultMetaData = {
    title: `Oferty nowych mieszkań i domów na sprzedaż - ${BRAND_NAME}`,
    description:
        "Wszystkie oferty nowych mieszkań i domów od deweloperów w jednym miejscu, oferty dla inwestorów, " +
        `aktualne promocje oraz wiadomości z rynku nieruchomości &#10004;. ${concatWithBrandName("Portal")} zaprasza &#128077;`,
    canonical: rpCanonicalUrl,
    lang: "pl"
};

export const UPDATE_META_DATA = "metaData/UPDATE_META_DATA";
export const UPDATE_META_TITLE_DESCRIPTION = "metaData/UPDATE_META_TITLE_DESCRIPTION";

export const standardMetaDataReducer = (
    state: IStandardMetaData = defaultMetaData,
    action: IUpdateMetaDataAction | IUpdateMetaTitleDescriptionAction
): IStandardMetaData => {
    switch (action.type) {
        case UPDATE_META_TITLE_DESCRIPTION:
            return {
                ...state,
                title: action.title !== "" ? action.title : state.title,
                description: action.description !== "" ? action.description : state.description
            };
        case UPDATE_META_DATA:
            return {
                title: action.title,
                description: action.description,
                canonical: action.canonical,
                prev: action.prev,
                next: action.next,
                robots: action.robots,
                ogDescription: action.ogDescription,
                ogImage: action.ogImage,
                ogTitle: action.ogTitle,
                ogType: action.ogType,
                ogUrl: action.ogUrl,
                ogPublishDate: action.ogPublishDate,
                dimensionRegion: action.dimensionRegion,
                gtmPage: action.gtmPage,
                hreflang: action.hreflang,
                lang: action.lang
            };
        default:
            return state;
    }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const metaDataReducerObj: Record<keyof IMetaDataStore, Reducer<any, any>> = {
    standardMetaData: standardMetaDataReducer,
    customMetaData: reduceResponse(customMetaActionTypes)
};

export const metaDataReducer = combineReducers(metaDataReducerObj);
