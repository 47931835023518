import {css} from "@emotion/react";

type Size = 25 | 33 | 50 | 66 | 75 | 100;

const width = (size: Size) => css`
    width: ${size}%;
    flex-basis: ${size}%;
`;

export const minWidth = (size: string) => css`
    min-width: ${size};
`;

export const maxWidth = (size: string) => css`
    max-width: ${size};
`;

export const w25 = width(25);
export const w33 = width(33);
export const w50 = width(50);
export const w66 = width(66);
export const w75 = width(75);
export const w100 = width(100);
export const resetWidth = css`
    width: unset;
    flex-basis: unset;
`;
