import {css} from "@emotion/react";
import {Property} from "csstype";

import {calculateRemSize, DEFAULT_SIZE, Size} from "./utils/rem";

export const flex = (align: Property.AlignItems = "normal", justify: Property.JustifyContent = "normal") => css`
    display: flex;
    align-items: ${align};
    justify-content: ${justify};
`;

export const flexDirection = (direction: Property.FlexDirection = "row") => css`
    flex-direction: ${direction};
`;

export const order = (no: number) => css`
    order: ${no};
`;

export const flexAbsoluteCenter = flex("center", "center");
export const flexJustifyCenter = flex("normal", "center");
export const flexAlignCenter = flex("center");
export const gap = (size: Size = DEFAULT_SIZE) => css`
    gap: ${calculateRemSize(size)};
`;
