import React from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {Button} from "@pg-design/button";
import {borderRadius, p, pb} from "@pg-design/helpers-css";
import {IPictureSource, Picture} from "@pg-design/picture-module";
import {Text} from "@pg-design/text-module";

import {useResponsiveLinkTarget} from "../../hooks/use_responsive_link_target";

interface IProps {
    description: string;
    link: string;
    pictureSources: IPictureSource[];
    title: string;
}

export const HomepageBlogCategory = (props: IProps) => {
    const target = useResponsiveLinkTarget();

    return (
        <div css={[homepageBlogCategory, borderRadius()]}>
            <Picture sources={props.pictureSources} alt="cover" />

            <div css={[content, p(2), pb(3)]}>
                <Text as="h2" fontFamily="secondary" mb="1.6rem" variant="headline_2">
                    {props.title}
                </Text>

                <Text variant="body_copy_1">{props.description}</Text>

                <StyledButton as="a" target={target} href={props.link} variant="outlined_secondary">
                    Zobacz artykuły
                </StyledButton>
            </div>
        </div>
    );
};

const homepageBlogCategory = css`
    overflow: hidden;
    max-width: 55.2rem;
`;

const content = css`
    background-color: #fff;
    min-height: 30.4rem;
    position: relative;
`;

const StyledButton = styled(Button)`
    position: absolute;
    bottom: 2.4rem;
    max-width: 20rem;

    &:hover,
    &:focus,
    &:active {
        color: ${(props) => props.theme.colors.secondary};
    }
`;
