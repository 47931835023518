import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {
    backgroundColor,
    borderRadius,
    calculateRemSize,
    flex,
    flexAbsoluteCenter,
    flexDirection,
    pl,
    pointer,
    position,
    pr,
    w100
} from "@pg-design/helpers-css";
import {SearchBoldIcon} from "@pg-design/icons-module";
import {InputCore, InputWrapper} from "@pg-design/inputs";

import {IRPStore} from "../../app/rp_reducer";
import {SearchTab} from "../../search/actions/fetch_search_all_action";
import {updateCurrentTab} from "../../search/actions/update_current_tab";
import {iconWrapper} from "../../search/components/search_autocomplete/SearchAutocompleteInput";

interface IProps {
    onClick: () => void;
}

export const MobileHomepageSearchPlaceholder = (props: IProps) => {
    const search = useSelector((state: IRPStore) => state.search);
    const dispatch = useDispatch();

    const isAbroadOrHolidayTab = [SearchTab.Abroad, SearchTab.Holiday].some((tab) => tab === search.currentTab);
    const inputPlaceholder = isAbroadOrHolidayTab ? "Wybierz lokalizację" : "Gdzie chcesz mieszkać";

    return (
        <div css={wrapper}>
            <div css={tabsWrapper}>
                <StyledTab onClick={() => dispatch(updateCurrentTab(SearchTab.Regions))} isActive={search.currentTab === SearchTab.Regions}>
                    W Polsce
                </StyledTab>
                <StyledTab onClick={() => dispatch(updateCurrentTab(SearchTab.Abroad))} isActive={search.currentTab === SearchTab.Abroad}>
                    Za granicą
                </StyledTab>
                <StyledTab onClick={() => dispatch(updateCurrentTab(SearchTab.Holiday))} isActive={search.currentTab === SearchTab.Holiday}>
                    Wakacyjne
                </StyledTab>
            </div>
            <div css={mobileHomepageSearchPlaceholder} onClick={props.onClick}>
                <div css={homepageIconWrapper}>
                    <SearchBoldIcon size="2.4" />
                </div>

                <StyledInputWrapper>
                    <InputCore placeholder={inputPlaceholder} readOnly />
                </StyledInputWrapper>
            </div>
        </div>
    );
};

const wrapper = css`
    ${flex()};
    max-width: 552px;
    width: calc(100% - 3.2rem);
    ${flexDirection("column")};
    ${borderRadius(2)};
    overflow: hidden;
`;

const mobileHomepageSearchPlaceholder = css`
    ${pointer};
    ${position("relative")};
    display: flex;
`;

const homepageIconWrapper = (theme: Theme) => css`
    ${iconWrapper};
    background-color: ${theme.colors.primary};
    ${flexAbsoluteCenter};
    width: 6.4rem;
    height: 100%;
`;

export const StyledInputWrapper = styled(InputWrapper)`
    height: 50px;
    ${pl(2)};
    ${pr(3)};
    ${w100};
    border: 0;
    ${borderRadius(0)};

    &:focus {
        outline: none;
    }
`;

const StyledTab = styled.div<{isActive: boolean}>`
    ${w100};
    ${borderRadius(0)};
    background-color: ${(props) => props.theme.colors.gray[props.isActive ? 300 : 200]};
    ${flexAbsoluteCenter};
    height: ${calculateRemSize(6)};
    cursor: pointer;

    :hover {
        background-color: ${(props) => props.theme.colors.gray[300]};
    }
`;

const tabsWrapper = (theme: Theme) => css`
    ${flex("center")};
    ${w100};
    ${backgroundColor(theme.colors.gray[200])};
    overflow: hidden;
    max-height: ${calculateRemSize(6)};
`;
