import {css} from "@emotion/react";
import {Property} from "csstype";

export const position = (value: Property.Position) => css`
    position: ${value};
`;

export const positionRelative = css`
    ${position("relative")};
`;
