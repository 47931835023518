import React from "react";
import {useParams} from "react-router";
import {css, Theme} from "@emotion/react";

import {getTextVariantStyleFor} from "@pg-design/text";
import {lowerFirst, upperFirst} from "@pg-mono/nodash";

import {SeoMainHeadline} from "../../atoms/SeoMainHeadline";
import {useOfferListingHeaderData} from "../list/hooks/use_offer_listing_header";
import {IOfferListingParams} from "../types/OfferListingParams";

interface IProps {
    isMap?: boolean;
}

export const ListMainHeader = (props: IProps) => {
    const {offerListSubType} = useParams<IOfferListingParams>();
    const {searchPrefix, searchPhrase, currentPage} = useOfferListingHeaderData();
    const pageTitle = `${searchPrefix.toLocaleLowerCase()} ${searchPhrase} ${currentPage}`.trim();

    if (props.isMap) {
        const mapH1RedundantText = "Mapa inwestycji - ";
        const h1WithoutBeginningOfTitle = pageTitle.substring(mapH1RedundantText.length);

        return (
            <SeoMainHeadline prefixContent="Szukasz (na mapie)" highlight css={headerText}>
                {lowerFirst(h1WithoutBeginningOfTitle)}
            </SeoMainHeadline>
        );
    }

    return (
        <SeoMainHeadline pageString={` ${currentPage}`} highlight css={headerText}>
            {upperFirst(offerListSubType ? searchPhrase : pageTitle)}
        </SeoMainHeadline>
    );
};

const headerText = (theme: Theme) => css`
    padding-bottom: 1rem;
    ${getTextVariantStyleFor("headline_6")(theme)};

    @media (min-width: ${theme.breakpoints.md}) {
        ${getTextVariantStyleFor("headline_2")(theme)};
    }
`;
