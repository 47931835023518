import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useDispatch} from "react-redux";
import type {Dispatch} from "redux";

import {useIsMobile, useIsMounted} from "@pg-mono/hooks";

import {ISentLeadActions, setDidSubmitOnMountAction} from "../../lead/reducers/lead_form_reducer";
import {ApplicationVariant} from "../types/ApplicationVariant";
import {IApplicationOwnProps} from "./Application";
import {ApplicationModalArray} from "./ApplicationModalArray";

type IProps = Omit<
    IApplicationOwnProps,
    "submitOnMount" | "initialLeadFormValues" | "leadData" | "appendPreMultileadApplications" | "handleChangeUserBudget"
> & {
    render: (rProps: IApplicationModalButtonRenderProps) => JSX.Element;
    openOnMount?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
    applicationVariant?: ApplicationVariant;
    creditPayload?: {price: number; contribution: number; period: number};
};

export interface IApplicationModalButtonRenderProps {
    isOpened: boolean;
    openModal: () => void;
    openModalWithSubmit: () => void;
}

export const ApplicationModalButton = (props: IProps) => {
    const {render, openOnMount, onOpen, onClose, ...restProps} = props;
    const isMobile = useIsMobile();
    const isMounted = useIsMounted();
    const dispatch: Dispatch<ISentLeadActions> = useDispatch();

    const [isOpened, setIsOpened] = useState(false);
    const [isOpenedWithSubmit, setIsOpenedWithSubmit] = useState<boolean | null>(false);

    const handleOpenModal = () => {
        onOpen?.();
        setIsOpened(true);
    };

    const openModal = useCallback(() => {
        handleOpenModal();
        setIsOpenedWithSubmit(false);
    }, []);

    const openModalWithSubmit = useCallback(() => {
        handleOpenModal();
        setIsOpenedWithSubmit(true);
    }, []);

    const closeModal = useCallback(() => {
        onClose?.();
        setIsOpened(false);
        dispatch<ISentLeadActions>(setDidSubmitOnMountAction(false));
    }, []);

    useEffect(() => {
        if (openOnMount && isOpenedWithSubmit !== null) {
            openModal();
        }
    }, []);

    const renderProps = useMemo(() => ({isOpened, openModal, openModalWithSubmit}), [isOpened, openModal, openModalWithSubmit]);

    return (
        <>
            {render(renderProps)}
            {isMounted && isOpened && isOpenedWithSubmit !== null && (
                <ApplicationModalArray closeModal={closeModal} isMobile={isMobile} isOpened={isOpened} submitOnMount={isOpenedWithSubmit} {...restProps} />
            )}
        </>
    );
};
