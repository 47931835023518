import React from "react";
import {css, Theme} from "@emotion/react";

import {underline} from "@pg-design/helpers-css";
import {BrandSearchIcon} from "@pg-design/icons-module";
import {Link} from "@pg-design/link";
import {rpAppLink} from "@pg-mono/rp-routes";

import {InfoPanel} from "../../atoms/InfoPanel";
import {ListMainHeader} from "./ListMainHeader";

interface IProps {
    className?: string;
    useHeader?: boolean;
}

export const EmptyList: React.FC<IProps> = (props) => {
    return (
        <>
            {props.useHeader && <ListMainHeader />}
            <InfoPanel
                css={props.className}
                icon={BrandSearchIcon}
                heading="Nie znaleziono ofert spełniających wybrane kryteria"
                headingAs="p"
                subHeading={
                    <>
                        Jeśli chcesz wyczyścić wyniki wyszukiwania, ponownie przejdź do{" "}
                        <Link css={link} href={rpAppLink.offer.listFriendly({friendlySlug: "nowe-mieszkania-i-domy"})}>
                            wyszukiwarki ofert
                        </Link>
                        .
                    </>
                }
                subHeadingAs="p"
            />
        </>
    );
};

const link = (theme: Theme) => css`
    ${underline};
    color: ${theme.colors.gray[700]};
`;
