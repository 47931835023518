export const TextVariant = {
    HEADLINE_0: "headline_0",
    HEADLINE_1: "headline_1",
    HEADLINE_2: "headline_2",
    HEADLINE_3: "headline_3",
    HEADLINE_4: "headline_4",
    HEADLINE_5: "headline_5",
    HEADLINE_6: "headline_6",
    BODY_COPY_0: "body_copy_0",
    BODY_COPY_1: "body_copy_1",
    BODY_COPY_2: "body_copy_2",
    INFO_TXT_1: "info_txt_1",
    INFO_TXT_2: "info_txt_2",
    INFO_TXT_3: "info_txt_3",
    BUTTON_BIG: "button_big",
    BUTTON_MEDIUM: "button_medium",
    BUTTON_SMALL: "button_small",
    BUTTON_MICRO: "button_micro",
    MINI_HEADER: "mini_header"
} as const;

export type ITextVariantType = typeof TextVariant;
export type TextVariantUnionType = ITextVariantType[keyof ITextVariantType];
