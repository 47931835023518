import React from "react";
import {css, Theme} from "@emotion/react";

import {flexAlignCenter, mv, pointer} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

import {animationOpacityScale} from "../../app/styles/animations";
import {Animated} from "../../atoms/Animated";
import {useResponsiveLinkTarget} from "../../hooks/use_responsive_link_target";

interface IProps {
    href: string;
    icon: React.ReactNode;
    title: string;
    text: string;
}

export const HomepageFeature = (props: IProps) => {
    const target = useResponsiveLinkTarget();

    const animation = animationOpacityScale(0.2, 0.2, true);

    return (
        <Animated animationRef={animation.ref} style={animation.style}>
            <a css={[homepageFeature, flexAlignCenter]} href={props.href} target={target}>
                {props.icon}
                <Text variant="headline_4" as="h3" css={mv(3)}>
                    {props.title}
                </Text>
                <Text align="center" variant="body_copy_1">
                    <span dangerouslySetInnerHTML={{__html: props.text}} />
                </Text>
            </a>
        </Animated>
    );
};

const homepageFeature = (theme: Theme) => css`
    flex-direction: column;

    &:hover,
    &:focus,
    &:active {
        ${pointer};
        color: ${theme.colors.secondary};
    }
`;
